@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/zindexes.module' as *;

$transition: ease-in-out 200ms;

$widthLogo: pxToRem(162);
$widthLogoContainer: pxToRem(162 - 64);
$widthLogoContainer--md: pxToRem(162 - 32);
$widthControls: pxToRem(162);

.root {
  height: $headerHeight;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  margin: auto;
  z-index: $zIndex-header;
  background: var(--bac-tie1);
  color: var(--tex-def-tie1);
  box-shadow: var(--elevation-2dp);
  transition:
    background $transition,
    color $transition,
    transform $transition;

  //
  // Styles for the search open
  //
  .links,
  :global(.HeaderMenuLinksTrack) {
    opacity: 1;
    transition: opacity 200ms linear;
  }
  :global(.SearchWidget-trigger) {
    transition:
      background linear 150ms,
      color linear 150ms,
      opacity 200ms linear;
  }
  &:has(:global(.SearchWidget-dialog[open])) {
    .links,
    :global(.HeaderMenuLinksTrack),
    :global(.SearchWidget-trigger) {
      opacity: 0;
    }
  }

  .controls {
    :global(.button) {
      color: inherit;
    }
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

//
// Styles for the headroom functionality
//
:global(body.scroll-down) {
  .root:not(:has(:focus-visible)) {
    transform: translate3d(0, -100%, 0);

    .logo {
      transform: translateY(#{pxToRem(-8)});
    }
  }
}

.container {
  @include rootSection(transparent);
  display: grid;
  grid-template: '🚗 🧑‍💼' 100% / $widthLogoContainer auto;
}

.verticalSpacer {
  height: $headerHeight;
}

.logo {
  grid-area: 🚗;
  width: $widthLogoContainer;
  height: $headerHeight;
  position: relative;
  box-sizing: border-box;

  .logoImage {
    display: block;
    width: $widthLogo;
    height: pxToRem(70);
    object-fit: contain;
    object-position: top center;
    margin: 0 pxToRem(-32);
  }
}

.links {
  grid-area: 😀;
  display: none;
}

.link {
  @include fontSize(17, 24);
  display: flex;
  color: inherit;
  justify-content: center;
  align-items: center;
  padding: pxToRem(16);
  height: $headerHeight;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;

  &:hover,
  &:global(.submenuActive) {
    .linkCaret {
      transform: rotate(180deg);
    }
  }

  .linkCaret {
    margin-left: pxToRem(6);
    transition: transform $transition;
  }
}

.controls {
  grid-area: 🧑‍💼;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.watchlist {
  margin-right: pxToRem(4);
}

//
// Styles for the transparent header
// Needs to have a .header-transparent anywhere in the document
//
:global(body:has(.header-transparent)),
:global(#__next:has(.header-transparent)) {
  .verticalSpacer {
    height: 0;
  }

  &:global(.scroll-top) {
    .root {
      background: rgba(255, 255, 255, 0);
      box-shadow: none;
    }
  }
}

:global(body:has(.header-homepage)),
:global(#__next:has(.header-homepage)) {
  &:global(.scroll-top) {
    .root {
      color: var(--tex-inv-tie1);
    }

    .controls {
      :global(.button) {
        &:hover,
        &:focus-visible {
          background-color: $color-opacity-light-lowEmphasis;
        }
      }
    }
  }
}

@include md {
  //
  // Undo styles for the headroom functionality
  //
  :global(body.scroll-down) {
    .root:not(:has(:focus-visible)) {
      transform: initial;

      .logo {
        transform: translateY(0);
      }
    }
  }

  .container {
    grid-template: '🚗 😀 🧑‍💼' 100% / $widthLogoContainer--md auto $widthControls;
  }

  .logo {
    width: $widthLogo;
    transition: border-right $transition;
    padding: 0 pxToRem(32);
    margin-left: pxToRem(-32);

    .logoImage {
      margin-right: 0;
      height: pxToRem(72);
    }
  }

  .links {
    display: flex;
    list-style: none;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 0 pxToRem(8);
    margin: 0;
  }
}

@include lg {
  .container {
    padding-left: 0;
    grid-template: '🚗 😀 🧑‍💼' 100% / $widthLogo auto $widthControls;
    transition: grid-template-columns $transition;
    will-change: grid-template-columns;
  }

  .logo {
    margin-left: 0;
  }

  .links {
    border-left: none;
  }
}
